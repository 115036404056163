/*
Videos
 */
export const TOGGLE_PLAYING_VIDEO = 'TOGGLE_PLAYING_VIDEO';
export const STOP_PLAYING_ALL_VIDEOS = 'STOP_PLAYING_ALL_VIDEOS';

/*
Services
 */
export const GET_SERVICES_START = 'GET_SERVICES_START';
export const GET_SERVICES_END = 'GET_SERVICES_END';
export const GET_SERVICES_ERROR = 'GET_SERVICES_ERROR';
export const MOVE_SERVICES_CAROUSEL = 'MOVE_SERVICES_CAROUSEL';

/*
Disciplines
 */
export const SWITCH_DISCIPLINES = 'SWITCH_DISCIPLINES';
export const SWITCH_DISCIPLINES_ERROR = 'SWITCH_DISCIPLINES_ERROR';
export const GET_DISCIPLINES_START = 'GET_DISCIPLINES_START';
export const GET_DISCIPLINES_END = 'GET_DISCIPLINES_END';
export const GET_DISCIPLINES_ERROR = 'GET_DISCIPLINES_ERROR';

/*
Modal
 */
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

/*
About
 */
export const SHOW_IN_VIEWPORT = 'SHOW_IN_VIEWPORT';
export const GET_ABOUT_PAGE_START = 'GET_ABOUT_PAGE_START';
export const GET_ABOUT_PAGE_END = 'GET_ABOUT_PAGE_END';
export const GET_ABOUT_PAGE_ERROR = 'GET_ABOUT_PAGE_ERROR';

/*
Offices
 */
export const GET_OFFICES_START = 'GET_OFFICES_START';
export const GET_OFFICES_END = 'GET_OFFICES_END';
export const GET_OFFICES_ERROR = 'GET_OFFICES_ERROR';

/*
Contact
 */
export const SUBMIT_CONTACT_START = 'SUBMIT_CONTACT_START';
export const SUBMIT_CONTACT_END = 'SUBMIT_CONTACT_END';
export const SUBMIT_CONTACT_ERROR = 'SUBMIT_CONTACT_ERROR';

/*
Projects
 */
export const GET_PROJECTS_START = 'GET_PROJECTS_START';
export const GET_PROJECTS_END = 'GET_PROJECTS_END';
export const GET_PROJECTS_ERROR = 'GET_PROJECTS_ERROR';
export const SEARCH_PROJECTS_START = 'SEARCH_PROJECTS_START';
export const SEARCH_PROJECTS_END = 'SEARCH_PROJECTS_END';
export const SEARCH_PROJECTS_ERROR = 'SEARCH_PROJECTS_ERROR';
export const GET_SINGLE_PROJECT_START = 'GET_SINGLE_PROJECT_START';
export const GET_SINGLE_PROJECT_END = 'GET_SINGLE_PROJECT_END';
export const GET_SINGLE_PROJECT_ERROR = 'GET_SINGLE_PROJECT_ERROR';
export const GET_SIMILAR_PROJECTS_START = 'GET_SIMILAR_PROJECTS_START';
export const GET_SIMILAR_PROJECTS_END = 'GET_SIMILAR_PROJECTS_END';
export const GET_SIMILAR_PROJECTS_ERROR = 'GET_SIMILAR_PROJECTS_ERROR';
export const LOAD_MORE_PROJECTS_START = 'LOAD_MORE_PROJECTS_START';
export const LOAD_MORE_PROJECTS_END = 'LOAD_MORE_PROJECTS_END';
export const LOAD_MORE_PROJECTS_ERROR = 'LOAD_MORE_PROJECTS_ERROR';
export const GET_PROJECTS_CATEGORIES_START = 'GET_PROJECTS_CATEGORIES_START';
export const GET_PROJECTS_CATEGORIES_END = 'GET_PROJECTS_CATEGORIES_END';
export const GET_PROJECTS_CATEGORIES_ERROR = 'GET_PROJECTS_CATEGORIES_ERROR';

/*
News
 */
export const GET_NEWS_START = 'GET_NEWS_START';
export const GET_NEWS_END = 'GET_NEWS_END';
export const GET_NEWS_ERROR = 'GET_NEWS_ERROR';
export const GET_SINGLE_ARTICLE_START = 'GET_SINGLE_ARTICLE_START';
export const GET_SINGLE_ARTICLE_END = 'GET_SINGLE_ARTICLE_END';
export const GET_SINGLE_ARTICLE_ERROR = 'GET_SINGLE_ARTICLE_ERROR';
export const GET_SIMILAR_NEWS_START = 'GET_SIMILAR_NEWS_START';
export const GET_SIMILAR_NEWS_END = 'GET_SIMILAR_NEWS_END';
export const GET_SIMILAR_NEWS_ERROR = 'GET_SIMILAR_NEWS_ERROR';
export const GET_NEXT_NEWS = 'GET_NEXT_NEWS_END';
export const GET_NEXT_NEWS_ERROR = 'GET_NEXT_NEWS_ERROR';

/*
Home page
 */
export const GET_HOMEPAGE_START = 'GET_HOMEPAGE_START';
export const GET_HOMEPAGE_END = 'GET_HOMEPAGE_END';
export const GET_HOMEPAGE_ERROR = 'GET_HOMEPAGE_ERROR';

/*
Services page
 */
export const GET_SERVICES_PAGE_START = 'GET_SERVICES_PAGE_START';
export const GET_SERVICES_PAGE_END = 'GET_SERVICES_PAGE_END';
export const GET_SERVICES_PAGE_ERROR = 'GET_SERVICES_PAGE_ERROR';

/*
Social links
 */
export const GET_SOCIAL_START = 'GET_SOCIAL_START';
export const GET_SOCIAL_END = 'GET_SOCIAL_END';
export const GET_SOCIAL_ERROR = 'GET_SOCIAL_ERROR';